import { getUserInfo } from '@/api/home';
import util from '@/libs/util.js';
import system from './system';
// import { SINGLE_TOKEN_KEY } from '@/maps/common';

export default {
  namespaced: true,
  state: {
    token: null,
    userInfo: {},
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data;
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data;
    },
  },
  actions: {
    /**
     * @description 设置用户token 并持久化到本地数据库
     * @param {Object} state vuex state
     */
    async setToken({ commit, dispatch }, data) {
      commit('SET_TOKEN', data);
      // 持久化
      await dispatch(
        'db/set',
        {
          dbName: 'sys',
          path: 'user.token',
          value: data,
          user: true,
        },
        { root: true },
      );
    },
    /**
     * @description 从数据库取用户token
     * @param {Object} state vuex state
     */
    async loadToken({ state, commit, dispatch }) {
      // store 赋值
      let token = await dispatch(
        'db/get',
        {
          dbName: 'sys',
          path: 'user.token',
          defaultValue: null,
          user: true,
        },
        { root: true },
      );
      const token_ = await util.getToken();
      if (token_ && !token) {
        token = token_;
        commit('SET_TOKEN', token);
        // 持久化
        await dispatch(
          'db/set',
          {
            dbName: 'sys',
            path: 'user.token',
            value: token,
            user: true,
          },
          { root: true },
        );
      }
      state.token = token;
    },

    /**
     * @description 设置用户info 并持久化到本地数据库
     * @param {Object} state vuex state
     */
    async setUserInfo({ commit, dispatch }, data) {
      commit('SET_USERINFO', data);
      // 持久化
      await dispatch(
        'db/set',
        {
          dbName: 'sys',
          path: 'user.userInfo',
          value: data,
          user: true,
        },
        { root: true },
      );
    },
    /**
     * @description 从数据库取用户info
     * @param {Object} state vuex state
     */
    async loadUserInfo({ state, commit, dispatch }) {
      // store 赋值
      let userInfo = await dispatch(
        'db/get',
        {
          dbName: 'sys',
          path: 'user.userInfo',
          defaultValue: null,
          user: true,
        },
        { root: true },
      );
      if (
        system.state.isLogin &&
        state.token &&
        (!userInfo || Object.keys(userInfo).length === 0)
      ) {
        const params = {
          appId: system.state.appid,
          ticket: state.token,
          toUuid: state.userInfo.uuid,
          uuid: state.userInfo.uuid,
        };
        const { data } = await getUserInfo(params);
        userInfo = data;
        commit('SET_USERINFO', data);
        // 持久化
        await dispatch(
          'db/set',
          {
            dbName: 'sys',
            path: 'user.userInfo',
            value: data,
            user: true,
          },
          { root: true },
        );
      }
      state.userInfo = userInfo;
    },
  },
};
