// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架之外显示
 */
const page = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: _import('home/index'),
  },
  {
    path: '/creator-program',
    name: 'CreatorProgram',
    component: _import('creator-program/index'),
    meta: {
      title: '创作者计划',
    },
  },
  {
    path: '/client-download',
    name: 'ClientDownload',
    component: _import('client-download/index'),
    meta: {
      title: '客户端下载',
    },
  },
  {
    path: '/r-space',
    name: 'Rspace',
    component: _import('r-space/index'),
    meta: {
      title: 'R空间',
    },
  },
  {
    path: '/argument',
    name: 'Argument',
    component: _import('home/argument'),
    meta: {
      title: '用户服务协议',
    },
  },
  {
    path: '/sdkUse',
    name: 'SdkUse',
    component: _import('home/sdkUse'),
    meta: {
      title: '第三方信息分享清单',
    },
  },
  {
    path: '/userInfoList',
    name: 'UserInfoList',
    component: _import('home/userInfoList'),
    meta: {
      title: '用户信息使用清单',
    },
  },
  {
    path: '/accountLogout',
    name: 'AccountLogout',
    component: _import('home/accountLogout'),
    meta: {
      title: '账号注销协议',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: _import('system/login/index'),
  },
];

// 重新组织后导出
export default [...page];
