export const SINGLE_TOKEN_KEY = 'cross_web_ticket'; // 用来设置给R 空间系统其他平台单点登录的token的标识
export const SINGLE_UUID_KEY = 'cross_web_uuid'; // 用来设置给R 空间系统其他平台单点登录的uuid的标识

export const httpCodeMap = {
  success: 200, // 成功
};

// 用户协议map，同运营平台数据字典中配置的数据
export const agreementMaps = {
  user: 1, // 用户协议
  privacy: 2, // 隐私协议
  sdkUse: 5, // SDK使用协议
  userInfoList: 6, // 用户信息收集清单
  logout: 7, // 账号注销协议
};

// 运营后台配置的数据字典-子系统
export const subSystemMaps = {
  space: 1, // 空间
  media: 2, // media创作空间
  ios: 3, // ios
  android: 4, // android
};
