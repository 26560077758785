import Vue from 'vue';
import VueRouter from 'vue-router';
// import api from '../server/apiSite'
// import axios from 'axios'
// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import util from '@/libs/util';
// 路由数据
import routes from './routes';
import mobileRoutes from './mobileRoutes';

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileKeywords =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

  return mobileKeywords.test(userAgent);
};

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err);
};
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: isMobile() ? mobileRoutes : routes,
});

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  const { meta: toMeta = {}, path: toPath } = to;
  const { auth = false } = toMeta;
  // 进度条
  NProgress.start();
  const token = await util.getToken();

  // 检查路径是否存在
  const validRoutes = isMobile() ? mobileRoutes : routes;
  const routeExists = validRoutes.some(route => route.path === toPath);

  if (!routeExists) {
    const redirectPath = isMobile() ? '/mobile/home' : '/home';
    next({ path: redirectPath });
    return;
  }

  if (token) {
    next();
  } else {
    // 需要登录则跳转到登录页
    if (auth && !isMobile()) {
      router.push({
        path: '/login',
        query: {
          redirect: encodeURIComponent(toPath),
        },
      });
    }
    next();
  }
});

router.afterEach(to => {
  // 进度条
  NProgress.done();
  // 更改标题
  util.setBomTitle(to.meta.title);
});

export default router;
