import request from '@/server/request';

/**
 * 登录/注册获取验证码
 * @param params
 * @param params.appId 系统ID
 * @param params.mt 手机号加密串
 * @param params.mobile 手机号码
 * @param params.uuid 用户编码
 */
export function getCaptcha(data) {
  return request({
    url: '/am/user/getCaptcha',
    method: 'POST',
    data,
  });
}

/**
 * 验证码登录
 * @param params
 * @param params.appId 系统ID
 * @param params.captcha 验证码
 * @param params.mobile 手机号码
 * @param params.uuid 用户编码
 */
export function mobileLogin(data) {
  return request({
    url: '/am/user/mobileLogin',
    method: 'POST',
    data,
  });
}

/**
 * 获取运营平台配置的协议
 * @param {*} params
 * @returns
 */
export function getAgreement(params) {
  return request({
    method: 'GET',
    url: '/system/user-agreement',
    params,
  });
}

/**
 * 用户基本信息
 * @param params
 * @param params.appId 系统ID
 * @param params.toUuid 普通用户id
 * @param params.ticket 登录ticket
 * @param params.uuid 用户编码
 */
export function getUserInfo(data) {
  return request({
    url: '/am/user/userInfo',
    method: 'POST',
    data,
  });
}

/**
 * 通过token登录
 * @param {} data
 * @returns
 */
export function loginByToken(data) {
  return request({
    url: '/am/user/login/token',
    method: 'POST',
    data,
  });
}

/**
 * 退出登录
 * @param params
 * @param params.appId 系统ID
 * @param params.ticket 登录ticket
 * @param params.uuid 用户编码
 */
export function logout(data) {
  return request({
    url: '/am/user/logout',
    method: 'POST',
    data,
  });
}

/** 获取二维码登录时的二维码数据 */
export function getQrcode(data) {
  return request({
    baseURL: process.env.VUE_APP_OPEN_SERVER,
    url: '/uc/qrLogin/createQr',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  });
}

/** 获取二维码登陆信息 */
export function getQrcodeInfo(params) {
  return request({
    baseURL: process.env.VUE_APP_OPEN_SERVER,
    url: '/uc/qrLogin/getInfo',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
