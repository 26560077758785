import cookies from './util.cookies';
import db from './util.db';
import store from '@/store';
// import log from './util.log'
// import browser from './util.browser'
// import dataType from './util.type'

// const { VUE_APP_PUBLIC_PATH } = process.env
import { SINGLE_TOKEN_KEY, SINGLE_UUID_KEY } from '@/maps/common';
import { loginByToken } from '@/api/home';

const util = {
  cookies,
  db,
  // log,
  // browser,
  // dataType
};

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileKeywords =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

  return mobileKeywords.test(userAgent);
};

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.setBomTitle = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || '';
  window.document.title = isMobile()
    ? titleText || processTitle
    : `${processTitle}${titleText ? ` | ${titleText}` : ''}`;
};

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.getTitle = function () {
  return process.env.VUE_APP_TITLE || '';
};

/**
 * @description 获取当前浏览器存储的token
 */
util.getToken = async () => {
  const singleToken = util.cookies.get(SINGLE_TOKEN_KEY);

  // 因为执行顺序问题，这里的数据直接从localstorage中取
  const isLogin = await store.dispatch(
    'db/get',
    {
      dbName: 'sys',
      path: 'system.isLogin',
      defaultValue: null,
      user: true,
    },
    { root: true },
  );
  const token = await store.dispatch(
    'db/get',
    {
      dbName: 'sys',
      path: 'user.token',
      defaultValue: null,
      user: true,
    },
    { root: true },
  );

  if (!isLogin && singleToken) {
    // 如果当前系统未登录，但是cookies中存在token，则采用token去登录
    await loginByTokenFun();
    return singleToken;
  } else if (isLogin) {
    // 如果当前系统已经登录，并且cookies中存储的token与当前系统存储的token不一致，则采用新的token去刷新登录信息
    if (singleToken && token !== singleToken) {
      await loginByTokenFun();
      return singleToken;
    } else if (!singleToken) {
      // 如果cookies中不存在token，但是系统在登录状态，说明是虚假登录状态，清除登录信息
      util.clearUser();
    } else {
      return singleToken;
    }
  }
};

/**
 * @description 跳转space
 */
util.jumpSpace = (type = '_blank') => {
  // const token = util.cookies.get(TOKEN_KEY);
  window.open(process.env.VUE_APP_SPACE_URL, type);
};

/** 清除登录信息 */
util.clearUser = () => {
  util.cookies.remove(SINGLE_TOKEN_KEY);
  util.cookies.remove(SINGLE_UUID_KEY);
  store.dispatch('user/setToken', null);
  store.dispatch('user/setUserInfo', null);
  store.dispatch('system/setIsLogin', false);
};

/** 通过单点登录的token进行登录
 * @description
 */
let isloading = false;
const loginByTokenFun = async () => {
  if (isloading) return;
  const singleToken = util.cookies.get(SINGLE_TOKEN_KEY);
  const singleUuid = util.cookies.get(SINGLE_UUID_KEY);
  const params = {
    ticket: singleToken,
    uuid: singleUuid,
    toUuid: singleUuid,
    appId: process.env.VUE_APP_ID,
  };
  isloading = true;
  const res = await loginByToken(params).catch(() => {
    util.clearUser();
  });
  isloading = false;
  if (res.data) {
    const { data } = res;

    // 存储在vuex中
    await store.dispatch('user/setToken', singleToken);
    // 设置用户info
    await store.dispatch('user/setUserInfo', data);
    // 设置系统为登录状态
    await store.dispatch('system/setIsLogin', true);
  } else {
    util.clearUser();
  }
};

/** 判断浏览器类型 */
util.getBroswer = () => {
  var userAgent = navigator.userAgent;
  var info = '';
  //判断浏览器版本
  var isOpera = userAgent.indexOf('Opera') > -1; //判断是否Opera浏览器
  var isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera; //判断是否IE浏览器
  var isEdge = userAgent.toLowerCase().indexOf('edg') > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.toLowerCase().indexOf('trident') > -1 && userAgent.indexOf('rv') > -1;

  if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
    info = 'Firefox';
  } else if (isIE || isIE11) {
    info += 'IE';
  } else if (isEdge) {
    info += 'Edge';
  } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
    info = 'Chrome';
  } else if (/[Ss]afari/.test(userAgent)) {
    info = 'Safari';
  } else if (isOpera) {
    info = 'Opera';
  } else {
    info += 'unknown';
  }
  return info;
};

/** 判断浏览器类型和版本号 */
util.getBroswerAndVersion = () => {
  var os = navigator.platform;
  var userAgent = navigator.userAgent;
  var info = '';
  var tempArray = '';
  //判断浏览器版本
  var isOpera = userAgent.indexOf('Opera') > -1; //判断是否Opera浏览器
  var isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera; //判断是否IE浏览器
  var isEdge = userAgent.toLowerCase().indexOf('edg') > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.toLowerCase().indexOf('trident') > -1 && userAgent.indexOf('rv') > -1;

  if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
    tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else if (isIE) {
    var version = '';
    var reIE = new RegExp('MSIE (\\d+\\.\\d+);');
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp['$1']);
    if (fIEVersion == 7) {
      version = 'IE7';
    } else if (fIEVersion == 8) {
      version = 'IE8';
    } else if (fIEVersion == 9) {
      version = 'IE9';
    } else if (fIEVersion == 10) {
      version = 'IE10';
    } else {
      version = '0';
    }

    info += version;
  } else if (isEdge) {
    info += 'Edge';
  } else if (isIE11) {
    info += 'IE11';
  } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
    tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) {
    tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent);
    info += tempArray[3] + tempArray[1];
  } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {
    tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else {
    info += 'unknown';
  }
  return info;
};
util.screenRatio = () => {
  return window.innerWidth / window.innerHeight;
};

export default util;
