export default {
  namespaced: true,
  state: {
    title: process.env.VUE_APP_TITLE || '', // 系统名称
    appid: process.env.VUE_APP_ID || '', // 系统id
    isLogin: false, // 是否登录了 包括单点登录
  },
  getters: {
    getTitle(state) {
      return state.title;
    },
    getAppID(state) {
      return state.appid;
    },
    getIsLogin(state) {
      return state.isLogin;
    },
  },
  mutations: {
    SET_ISLOGIN: (state, data) => {
      state.isLogin = data;
    },
  },
  actions: {

    /**
     * @description 设置系统登录状态
     * @param {Object} state vuex state
     */
    async setIsLogin({ commit, dispatch }, data) {
      commit('SET_ISLOGIN', data);
      // 持久化
      await dispatch(
        'db/set',
        {
          dbName: 'sys',
          path: 'system.isLogin',
          value: data,
          user: true,
        },
        { root: true },
      );
    },

    /**
     * @description 从数据库取用户isLogin
     * @param {Object} state vuex state
     */
    async loadIsLogin({ state, dispatch }) {
      // store 赋值
      const isLogin = await dispatch(
        'db/get',
        {
          dbName: 'sys',
          path: 'system.isLogin',
          defaultValue: null,
          user: true,
        },
        { root: true },
      );
      state.isLogin = isLogin;
    },

    /**
     * @description 从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     */
    async load({ dispatch }) {
      // DB -> store 加载用户token
      await dispatch('user/loadToken', null, { root: true });
      // DB -> store 加载用户info
      await dispatch('user/loadUserInfo', null, { root: true });
      await dispatch('system/loadIsLogin', null, { root: true });
    },
  },
};
